import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-3">
                <p>Torna el millor curs online de psicos en directe, ara els divendres!</p>

                <p>Un curs completament renovat amb nous exercicis adaptats als oficials.</p>

                <p>
                    <strong>12 hores de formació en directe</strong> junt amb exercicis a través del
                    campus per poder practicar.
                </p>

                <p>
                    <strong>Dates del curs:</strong>
                </p>
                <ul>
                    <li>Divendres, 17 de gener de 18.00 a 22.00 h</li>
                    <li>Divendres, 24 de gener de 18.00 a 22.00 h</li>
                    <li>Divendres, 7 de febrer de 18.00 a 22.00 h</li>
                </ul>

                <p>
                    <strong>Metodologia:</strong>
                </p>
                <ul>
                    <li>
                        Serà un curs molt especial ja que serà impartit per
                        <strong>2 docents</strong> que es combinaran les classes.
                    </li>
                    <li>Exercicis totalment nous i adaptats a les últimes convocatòries.</li>
                    <li>
                        Totes les classes són en directe i queden gravades fins el
                        <strong>15 de febrer</strong> al campus per visionar tantes vegades es
                        vulgui.
                    </li>
                    <li>
                        Durant la setmana de descans del 27 de gener al 2 de febrer, cada día us
                        penjarem un{" "}
                        <span role="img" aria-label="kahoot">
                            📚
                        </span>
                        kahoot de psicos i un simulacre òmnibus.
                    </li>
                </ul>

                <p>
                    <strong>Al campus trobaràs:</strong>
                </p>
                <ul>
                    <li>Vídeos explicatius</li>
                    <li>Exercicis interactius</li>
                </ul>

                <p>
                    L'objectiu d'aquest curs intensiu és oferir-te les eines necessàries per
                    afrontar la prova amb les màximes garanties. Sabem que cada any molts aspirants
                    es queden a les portes, i no volem que això et passi a tu.
                </p>

                <p>
                    Cada raonament compta, i tots tenen el mateix valor a la prova aptitudinal:
                    raonament abstracte, espacial, verbal, numèric i perceptiu.
                </p>

                <p>
                    Cada lògica es tractarà de forma individual i amb exercicis on detallarem tot el
                    que necessites per poder tenir èxit en aquesta prova.
                </p>

                <p>
                    Explicació i anàlisi de tots els raonaments que surten a la prova (
                    <strong>raonament abstracte, espacial, verbal, numèric i perceptiu</strong>).
                </p>

                <p>
                    <strong>T'esperem!</strong>
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/YUe0b5qv5F0"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Transforma el teu futur amb el nostre curs intensiu de psicos: 12 hores de formació en directe, exercicis nous i tot el suport que necessites per dominar la prova i garantir el teu èxit. Inscriu-te ara i obtingues l'avantatge que et farà destacar!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
